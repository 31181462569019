@import "https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,300;0,400;0,700;0,800;0,900;1,300;1,400;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap";
*,
:after,
:before {
  -webkit-font-smoothing: antialiased;
}
blockquote,
body,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
input,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a.btn:active,
a.btn:focus,
button:focus {
  border: 0;
  outline: 0;
}
button::-moz-focus-inner {
  border: 0;
  outline: 0;
}
input::-moz-focus-inner {
  border: 0;
  outline: 0;
}
input[type="reset"]::-moz-focus-inner {
  border: 0;
  outline: 0;
}
input[type="button"]::-moz-focus-inner {
  border: 0;
  outline: 0;
}
input[type="submit"]::-moz-focus-inner {
  border: 0;
  outline: 0;
}
select::-moz-focus-inner {
  border: 0;
  outline: 0;
}
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: 0;
  outline: 0;
}
button,
fieldset,
iframe,
img,
input,
textarea {
  border: 0;
}
li,
ol,
ul {
  list-style: none;
}
html {
  margin: 0 auto;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
a,
button,
img:active,
input,
textarea {
  outline: 0;
}
a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
button {
  background: initial;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
div,
div iframe,
div img,
embed,
object,
param {
  vertical-align: top;
}
a,
label {
  display: inline-block;
  vertical-align: middle;
}
input,
textarea {
  vertical-align: middle;
}
body {
  overflow-x: hidden;
}
textarea {
  resize: vertical;
}
img {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
body,
html {
  text-rendering: optimizeLegibility;
}
::-webkit-input-placeholder {
  text-transform: none;
}
:-moz-placeholder {
  text-transform: none;
}
::-moz-placeholder {
  text-transform: none;
}
:-ms-input-placeholder {
  text-transform: none;
}
button,
input,
select {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.ignore_all,
.ignore_all * {
  all: initial;
}
.alert-primary {
  color: #2f69be;
  background-color: #ccf2ff;
  border-color: #b7edff;
}
.alert-primary hr {
  border-top-color: #9ee7ff;
}
.alert-primary .alert-link {
  color: #172958;
}
.badge-primary {
  color: #fff;
  background-color: #12bcff;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  background-color: #172958;
}
.bg-primary {
  background-color: #12bcff !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #172958 !important;
}
.border-primary {
  border-color: #12bcff !important;
}
.btn-primary {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #172958;
  border-color: #172958;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #172958;
  border-color: #1e4a89;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.btn-outline-primary {
  color: #12bcff;
  background-color: transparent;
  border-color: #12bcff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #12bcff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.list-group-item-primary {
  color: #2f69be;
  background-color: #b7edff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #2f69be;
  background-color: #9ee7ff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2f69be;
  border-color: #2f69be;
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b7edff;
}
.table-hover .table-primary:hover {
  background-color: #9ee7ff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9ee7ff;
}
.text-primary {
  color: #12bcff !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #172958 !important;
}
.split-name {
  background-color: #fff;
}
.color-light {
  color: #fff;
}
.color-light-hover:hover {
  color: #fff;
}
.bg-light {
  background-color: #fff;
}
.split-name {
  background-color: #fff;
}
.color-light-theme {
  color: #fff;
}
.color-light-theme-hover:hover {
  color: #fff;
}
.bg-light-theme {
  background-color: #fff;
}
.split-name {
  background-color: #36b31d;
}
.color-green {
  color: #36b31d;
}
.color-green-hover:hover {
  color: #36b31d;
}
.bg-green {
  background-color: #36b31d;
}
.split-name {
  background-color: #b900d1;
}
.color-purple {
  color: #b900d1;
}
.color-purple-hover:hover {
  color: #b900d1;
}
.bg-purple {
  background-color: #b900d1;
}
.split-name {
  background-color: #2f69be;
}
.color-primary-deep {
  color: #2f69be;
}
.color-primary-deep-hover:hover {
  color: #2f69be;
}
.bg-primary-deep {
  background-color: #2f69be;
}
.split-name {
  background-color: #172958;
}
.color-primary-accent {
  color: #172958;
}
.color-primary-accent-hover:hover {
  color: #172958;
}
.bg-primary-accent {
  background-color: #172958;
}
.split-name {
  background-color: #d17d00;
}
.color-brown {
  color: #d17d00;
}
.color-brown-hover:hover {
  color: #d17d00;
}
.bg-brown {
  background-color: #d17d00;
}
.split-name {
  background-color: #000;
}
.color-black {
  color: #000;
}
.color-black-hover:hover {
  color: #000;
}
.bg-black {
  background-color: #000;
}
.split-name {
  background-color: #606060;
}
.color-darkness {
  color: #606060;
}
.color-darkness-hover:hover {
  color: #606060;
}
.bg-darkness {
  background-color: #606060;
}
.split-name {
  background-color: #e2e2e2;
}
.color-light-gray {
  color: #e2e2e2;
}
.color-light-gray-hover:hover {
  color: #e2e2e2;
}
.bg-light-gray {
  background-color: #e2e2e2;
}
.split-name {
  background-color: #ddf8ff;
}
.color-light-blue {
  color: #ddf8ff;
}
.color-light-blue-hover:hover {
  color: #ddf8ff;
}
.bg-light-blue {
  background-color: #ddf8ff;
}
.split-name {
  background-color: #f5e5e5;
}
.color-light-red {
  color: #f5e5e5;
}
.color-light-red-hover:hover {
  color: #f5e5e5;
}
.bg-light-red {
  background-color: #f5e5e5;
}
.split-name {
  background-color: #eaeaea;
}
.color-highlight {
  color: #eaeaea;
}
.color-highlight-hover:hover {
  color: #eaeaea;
}
.bg-highlight {
  background-color: #eaeaea;
}
.split-name {
  background-color: #6f6f6f;
}
.color-dark {
  color: #6f6f6f;
}
.color-dark-hover:hover {
  color: #6f6f6f;
}
.bg-dark {
  background-color: #6f6f6f;
}
.split-name {
  background-color: #232323;
}
.color-dark-2 {
  color: #232323;
}
.color-dark-2-hover:hover {
  color: #232323;
}
.bg-dark-2 {
  background-color: #232323;
}
.split-name {
  background-color: #c1c1c1;
}
.color-gray {
  color: #c1c1c1;
}
.color-gray-hover:hover {
  color: #c1c1c1;
}
.bg-gray {
  background-color: #c1c1c1;
}
.split-name {
  background-color: #12bcff;
}
.color-primary {
  color: #12bcff;
}
.color-primary-hover:hover {
  color: #12bcff;
}
.bg-primary {
  background-color: #12bcff;
}
.split-name {
  background-color: #ffb600;
}
.color-secondary {
  color: #ffb600;
}
.color-secondary-hover:hover {
  color: #ffb600;
}
.bg-secondary {
  background-color: #ffb600;
}
.split-name {
  background-color: #ffb600;
}
.color-secondary-theme {
  color: #ffb600;
}
.color-secondary-theme-hover:hover {
  color: #ffb600;
}
.bg-secondary-theme {
  background-color: #ffb600;
}
.split-name {
  background-color: #fe6154;
}
.color-secondary-accent {
  color: #fe6154;
}
.color-secondary-accent-hover:hover {
  color: #fe6154;
}
.bg-secondary-accent {
  background-color: #fe6154;
}
.split-name {
  background-color: #ec7700;
}
.color-orange {
  color: #ec7700;
}
.color-orange-hover:hover {
  color: #ec7700;
}
.bg-orange {
  background-color: #ec7700;
}
.bg-color-primary-alpha-60 {
  background-color: rgba(18, 188, 255, 0.6);
}
.bg-color-primary-alpha-40 {
  background-color: rgba(18, 188, 255, 0.4);
}
html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #6f6f6f;
  line-height: 1.7;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
html {
  scroll-behavior: smooth;
}
body {
  padding-top: 110px;
}
@media (min-width: 992px) {
  body {
    padding-top: 145px;
  }
}
body section {
  padding: 40px 25px;
}
@media (min-width: 768px) {
  body section {
    padding: 80px 40px;
  }
}
@media (min-width: 768px) {
  .boxed {
    z-index: 2;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
.hero-title {
  font-weight: normal;
  font-size: 32px;
}
@media (min-width: 768px) {
  .hero-title {
    font-size: 35px;
  }
}
@media (min-width: 1200px) {
  .hero-title {
    font-size: 50px;
  }
}
.hero-title--md {
  font-size: 35px;
}
@media (min-width: 768px) {
  .hero-title--lg {
    font-size: 50px;
  }
}
@media (min-width: 1200px) {
  .hero-title--lg {
    font-size: 60px;
  }
}
.headline-title {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: normal;
  font-family: "Archivo", sans-serif;
}
.headline-title--size-lg {
  font-size: 32px;
}
.headline-title--size-xl {
  font-size: 32px;
}
@media (min-width: 768px) {
  .headline-title--size-xl {
    font-size: 35px;
  }
}
.headline-body {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 300;
}
.headline-body--size-sm {
  font-size: 25px;
}
.headline-body--size-xxl {
  font-size: 32px;
}
@media (min-width: 768px) {
  .headline-body--size-xxl {
    font-size: 50px;
  }
}
.headline-subtitle {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: normal;
}
.section--sm {
  padding: 50px 25px;
}
.section--md {
  padding: 80px 25px;
}
@media (min-width: 768px) {
  .section--md {
    padding: 120px 40px;
  }
}
.section--lg {
  padding: 100px 25px;
}
@media (min-width: 768px) {
  .section--lg {
    padding: 140px 40px;
  }
}
.section--hero {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (min-width: 768px) {
  .section--hero {
    padding-top: 200px;
    padding-bottom: 150px;
  }
}
.section--spacing-top-md {
  padding-top: 80px;
}
@media (min-width: 768px) {
  .section--spacing-top-md {
    padding-top: 100px;
  }
}
.wrap--align-center {
  margin-left: auto;
  margin-right: auto;
}
.wrap--size-md {
  max-width: 700px;
}
.wrap--center {
  margin-left: auto;
  margin-right: auto;
}
.wrap--md {
  max-width: 800px;
}
.wrap--sm {
  max-width: 700px;
}
.bg_image_full {
  position: relative;
}
.bg_image_full:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-position: center;
}
.headline-body {
  position: relative;
}
.headline-body.icon-headline-container {
  position: relative;
}
@media (min-width: 768px) {
  .headline-body.icon-headline-container {
    padding-left: 90px;
  }
}
.headline-body.icon-headline-container .icon-headline {
  background-color: #12bcff;
  color: #fff;
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .headline-body.icon-headline-container .icon-headline {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 40px;
    height: 40px;
  }
}
.headline-body.icon-headline-container .icon-headline img {
  position: absolute;
  width: calc(100% - 15px);
  height: calc(100% - 15px);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.icon-circle {
  position: relative;
  background-color: #12bcff;
  color: #fff;
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
}
.icon-circle img {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.item-box {
  padding: 40px;
}
p a {
  text-decoration: underline;
}
.btn,
[class*="btn-"] {
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fab,
.fas,
.far {
  font-size: 20px;
}
.fab.size-sm,
.fas.size-sm,
.far.size-sm {
  font-size: 16px;
}
.fab.size-md,
.fas.size-md,
.far.size-md {
  font-size: 30px;
}
.fab.size-lg,
.fas.size-lg,
.far.size-lg {
  font-size: 60px;
}
.footer {
  padding: 60px 40px;
}
.footer a {
  color: inherit;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
}
.footer a:hover {
  color: #12bcff;
}
.footer__copyright {
  color: rgba(255, 255, 255, 0.5);
}
.bottom-bar {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
  padding: 10px 40px;
  background-color: rgba(0, 0, 0, 0.8);
}
.bottom-bar a {
  color: inherit;
}
.bottom-bar a:hover {
  color: #fff;
}
.bottom-bar .fas {
  font-size: 16px;
}
.alert-primary {
  color: #2f69be;
  background-color: #ccf2ff;
  border-color: #b7edff;
}
.alert-primary hr {
  border-top-color: #9ee7ff;
}
.alert-primary .alert-link {
  color: #172958;
}
.badge-primary {
  color: #fff;
  background-color: #12bcff;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  background-color: #172958;
}
.bg-primary {
  background-color: #12bcff !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #172958 !important;
}
.border-primary {
  border-color: #12bcff !important;
}
.btn-primary {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #172958;
  border-color: #172958;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #172958;
  border-color: #1e4a89;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.btn-outline-primary {
  color: #12bcff;
  background-color: transparent;
  border-color: #12bcff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #12bcff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.list-group-item-primary {
  color: #2f69be;
  background-color: #b7edff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #2f69be;
  background-color: #9ee7ff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2f69be;
  border-color: #2f69be;
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b7edff;
}
.table-hover .table-primary:hover {
  background-color: #9ee7ff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9ee7ff;
}
.text-primary {
  color: #12bcff !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #172958 !important;
}
.split-name {
  background-color: #fff;
}
.color-light {
  color: #fff;
}
.color-light-hover:hover {
  color: #fff;
}
.bg-light {
  background-color: #fff;
}
.split-name {
  background-color: #fff;
}
.color-light-theme {
  color: #fff;
}
.color-light-theme-hover:hover {
  color: #fff;
}
.bg-light-theme {
  background-color: #fff;
}
.split-name {
  background-color: #36b31d;
}
.color-green {
  color: #36b31d;
}
.color-green-hover:hover {
  color: #36b31d;
}
.bg-green {
  background-color: #36b31d;
}
.split-name {
  background-color: #b900d1;
}
.color-purple {
  color: #b900d1;
}
.color-purple-hover:hover {
  color: #b900d1;
}
.bg-purple {
  background-color: #b900d1;
}
.split-name {
  background-color: #2f69be;
}
.color-primary-deep {
  color: #2f69be;
}
.color-primary-deep-hover:hover {
  color: #2f69be;
}
.bg-primary-deep {
  background-color: #2f69be;
}
.split-name {
  background-color: #172958;
}
.color-primary-accent {
  color: #172958;
}
.color-primary-accent-hover:hover {
  color: #172958;
}
.bg-primary-accent {
  background-color: #172958;
}
.split-name {
  background-color: #d17d00;
}
.color-brown {
  color: #d17d00;
}
.color-brown-hover:hover {
  color: #d17d00;
}
.bg-brown {
  background-color: #d17d00;
}
.split-name {
  background-color: #000;
}
.color-black {
  color: #000;
}
.color-black-hover:hover {
  color: #000;
}
.bg-black {
  background-color: #000;
}
.split-name {
  background-color: #606060;
}
.color-darkness {
  color: #606060;
}
.color-darkness-hover:hover {
  color: #606060;
}
.bg-darkness {
  background-color: #606060;
}
.split-name {
  background-color: #e2e2e2;
}
.color-light-gray {
  color: #e2e2e2;
}
.color-light-gray-hover:hover {
  color: #e2e2e2;
}
.bg-light-gray {
  background-color: #e2e2e2;
}
.split-name {
  background-color: #ddf8ff;
}
.color-light-blue {
  color: #ddf8ff;
}
.color-light-blue-hover:hover {
  color: #ddf8ff;
}
.bg-light-blue {
  background-color: #ddf8ff;
}
.split-name {
  background-color: #f5e5e5;
}
.color-light-red {
  color: #f5e5e5;
}
.color-light-red-hover:hover {
  color: #f5e5e5;
}
.bg-light-red {
  background-color: #f5e5e5;
}
.split-name {
  background-color: #eaeaea;
}
.color-highlight {
  color: #eaeaea;
}
.color-highlight-hover:hover {
  color: #eaeaea;
}
.bg-highlight {
  background-color: #eaeaea;
}
.split-name {
  background-color: #6f6f6f;
}
.color-dark {
  color: #6f6f6f;
}
.color-dark-hover:hover {
  color: #6f6f6f;
}
.bg-dark {
  background-color: #6f6f6f;
}
.split-name {
  background-color: #232323;
}
.color-dark-2 {
  color: #232323;
}
.color-dark-2-hover:hover {
  color: #232323;
}
.bg-dark-2 {
  background-color: #232323;
}
.split-name {
  background-color: #c1c1c1;
}
.color-gray {
  color: #c1c1c1;
}
.color-gray-hover:hover {
  color: #c1c1c1;
}
.bg-gray {
  background-color: #c1c1c1;
}
.split-name {
  background-color: #12bcff;
}
.color-primary {
  color: #12bcff;
}
.color-primary-hover:hover {
  color: #12bcff;
}
.bg-primary {
  background-color: #12bcff;
}
.split-name {
  background-color: #ffb600;
}
.color-secondary {
  color: #ffb600;
}
.color-secondary-hover:hover {
  color: #ffb600;
}
.bg-secondary {
  background-color: #ffb600;
}
.split-name {
  background-color: #ffb600;
}
.color-secondary-theme {
  color: #ffb600;
}
.color-secondary-theme-hover:hover {
  color: #ffb600;
}
.bg-secondary-theme {
  background-color: #ffb600;
}
.split-name {
  background-color: #fe6154;
}
.color-secondary-accent {
  color: #fe6154;
}
.color-secondary-accent-hover:hover {
  color: #fe6154;
}
.bg-secondary-accent {
  background-color: #fe6154;
}
.split-name {
  background-color: #ec7700;
}
.color-orange {
  color: #ec7700;
}
.color-orange-hover:hover {
  color: #ec7700;
}
.bg-orange {
  background-color: #ec7700;
}
.bg-color-primary-alpha-60 {
  background-color: rgba(18, 188, 255, 0.6);
}
.bg-color-primary-alpha-40 {
  background-color: rgba(18, 188, 255, 0.4);
}
body .top-bar {
  background-color: #ecf9ff;
  color: #000;
  font-size: 14px;
}
body .top-bar .header-info {
  padding: 5px 20px;
}
body .top-bar .fas,
body .top-bar .fas {
  color: #12bcff;
  font-size: 16px;
  margin-right: 10px;
}
body .top-bar a {
  color: #12bcff;
}
body .sidebar {
  position: relative;
  z-index: 99;
}
body .sidebar #sidebar-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100vh;
  margin-right: 0;
  width: 100%;
  max-width: 15rem;
  z-index: 2;
  background-color: #fff;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
@media (min-width: 576px) {
  body .sidebar #sidebar-wrapper {
    max-width: 20rem;
  }
}
body .sidebar #sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
body .sidebar #sidebar-wrapper .list-group {
  width: 15rem;
}
@media (min-width: 576px) {
  body .sidebar #sidebar-wrapper .list-group {
    width: 20rem;
  }
}
body .sidebar #sidebar-wrapper .list-group .list-group-item {
  border-left: 0;
  border-right: 0;
}
body .sidebar #sidebar-wrapper .list-group .list-group-item:last-child {
  border-bottom: 0;
}
body .sidebar #sidebar-wrapper .submenu-mobile-item {
  position: relative;
}
body .sidebar #sidebar-wrapper .submenu-mobile-item .arrow-collapse {
  position: absolute;
  padding: 12px;
  right: 0;
  top: 0;
  z-index: 1;
  width: 50%;
}
body .sidebar #sidebar-wrapper .submenu-mobile-item .arrow-collapse button {
  width: 100%;
  text-align: right;
}
body
  .sidebar
  #sidebar-wrapper
  .submenu-mobile-item
  .arrow-collapse
  button
  .fas {
  color: #12bcff;
  font-size: 12px;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
body
  .sidebar
  #sidebar-wrapper
  .submenu-mobile-item
  .arrow-collapse
  button.collapsed
  .fas {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
body .sidebar #sidebar-wrapper .submenu-mobile {
  padding: 0;
}
body .sidebar #sidebar-wrapper .submenu-mobile .list-group-item:first-child {
  border-top: 0;
}
@media (min-width: 768px) {
  body .sidebar #sidebar-wrapper {
    margin-right: 0;
  }
}
body.toggled #sidebar-wrapper {
  margin-right: -20rem;
}
body .menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
body:not(.toggled) .menu-overlay {
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
  visibility: visible;
}
body .navbar-container {
  position: fixed;
  top: 0;
  min-height: 110px;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  -o-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
body .navbar-container .navbar-wrapper {
  padding: 10px 20px;
}
@media (min-width: 576px) {
  body .navbar-container .navbar-wrapper {
    padding: 10px 40px;
  }
}
@media (min-width: 992px) {
  body .navbar-container .navbar-wrapper {
    padding: 10px 20px;
  }
}
body .navbar-container .navbar {
  padding: 0;
}
body .navbar-container .navbar .header-logo {
  width: 100%;
  max-width: 100%;
}
body .navbar-container .navbar .menu-toggle .fas {
  font-size: 20px;
}
body .navbar-container .navbar .nav-link {
  padding: 10px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  text-transform: uppercase;
}
body .navbar-container .navbar .nav-link a {
  color: #000;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
body .navbar-container .navbar .nav-link a:hover {
  color: #12bcff;
  text-decoration: none;
}
body .navbar-container .navbar .nav-link:hover .submenu {
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
  visibility: visible;
}
body .navbar-container .navbar .nav-link:hover .submenu .submenu-items:before {
  width: 80px;
}
body
  .navbar-container
  .navbar
  .nav-link:hover
  .submenu
  .submenu-items:hover:before {
  width: 100%;
}
body .navbar-container .navbar .nav-link .submenu {
  width: max-content;
  min-width: max-content;
  position: absolute;
  top: 100%;
  visibility: hidden;
  padding-top: 22px;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
body .navbar-container .navbar .nav-link .submenu .submenu-items {
  position: relative;
  font-weight: 300;
  background-color: #fff;
}
body .navbar-container .navbar .nav-link .submenu .submenu-items:before {
  content: "";
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 0;
  height: 3px;
  background: #12bcff;
  position: absolute;
  top: 0;
  left: 0;
}
body .navbar-container .navbar .nav-link .submenu .submenu-items a {
  padding: 10px 20px;
  display: block;
  font-size: 16px;
}
body .navbar-container .navbar .nav-item:hover .nav-link,
body .navbar-container .navbar .nav-item.active .nav-link {
  text-decoration: none;
  color: #12bcff;
}
.swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-nav {
  position: relative;
}
@media (min-width: 768px) {
  .swiper-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 0;
    width: 100%;
    max-width: 1100px;
  }
}
.swiper-nav .button-nav-prev,
.swiper-nav .button-nav-next {
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  .swiper-nav .button-nav-prev,
  .swiper-nav .button-nav-next {
    position: absolute;
    z-index: 1;
  }
}
.swiper-nav .button-nav-prev:active,
.swiper-nav .button-nav-prev:focus,
.swiper-nav .button-nav-next:active,
.swiper-nav .button-nav-next:focus {
  outline: 0;
}
.swiper-nav .button-nav-prev {
  left: 0;
}
.swiper-nav .button-nav-next {
  right: 0;
}
.btn-oval {
  padding: 8px 25px;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  border-radius: 10rem;
}
.btn-hero {
  padding: 6px 30px;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  border-radius: 10rem;
  font-size: 16px;
  font-family: "Archivo", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}
.btn-oval {
  padding: 6px 30px;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  border-radius: 10rem;
  font-weight: bold;
}
.btn-secondary {
  color: #fff;
  background-color: #ffb600;
  border-color: #ffb600;
}
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-link.color-light:hover {
  color: #fff;
}
.btn-light {
  color: #12bcff;
  border-color: transparent;
}
.btn-light:not(.disabled):active:focus,
.btn-light:focus,
.btn-light:hover {
  border-color: transparent;
  background-color: #172958;
  color: #fff;
}
.btn:focus,
.btn:active,
.btn:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.toggle-show-hide__hide {
  display: none;
}
.toggle-show-hide[aria-expanded*="true"] .toggle-show-hide__hide {
  display: block;
}
.toggle-show-hide[aria-expanded*="true"] .toggle-show-hide__show {
  display: none;
}
.card {
  border: 0;
}
.card.card-boxed {
  max-width: 380px;
  margin: 0 auto;
}
[class*="split"] {
  width: 80px;
  height: 3px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  border-radius: 10rem;
  border: 0;
}
.icon-sm {
  font-size: 16px;
}
.iframe-responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.iframe-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hihglight-even > .row {
  position: relative;
  border-bottom: 1px solid rgba(111, 111, 111, 0.2);
}
.hihglight-even > .row:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
}
.hihglight-even > .row:hover:before {
  filter: alpha(opacity=10);
  -moz-opacity: 0.1;
  opacity: 0.1;
}
.hihglight-even--primary > .row:before {
  background-color: #12bcff;
}
.hihglight-even--orange > .row:before {
  background-color: #ffb600;
}
.hihglight-even--green > .row:before {
  background-color: #36b31d;
}
.hihglight-even--purple > .row:before {
  background-color: #b900d1;
}
.hihglight-even--brown > .row:before {
  background-color: #d17d00;
}
.hihglight-even--gray > .row:before {
  background-color: #c1c1c1;
}
.box-highlight {
  color: #fff;
  position: relative;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: #12bcff;
}
@media (min-width: 768px) {
  .box-highlight {
    color: #12bcff;
  }
}
.box-highlight .headline-subtitle {
  color: inherit;
}
.modal-action-icon {
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #12bcff;
  margin: 0 3px;
}
.modal-action-icon:hover {
  text-decoration: none;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}
.box-highlight:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  background-color: #12bcff;
}
@media (min-width: 768px) {
  .box-highlight:before {
    filter: alpha(opacity=10);
    -moz-opacity: 0.1;
    opacity: 0.1;
  }
}
.box-highlight--orange {
  border-left-color: #ffb600;
}
@media (min-width: 768px) {
  .box-highlight--orange {
    color: #ffb600;
  }
}
.box-highlight--orange:before {
  background-color: #ffb600;
}
@media (min-width: 768px) {
  .box-highlight--orange:before {
    color: #ffb600;
  }
}
.box-highlight--green {
  border-left-color: #36b31d;
}
@media (min-width: 768px) {
  .box-highlight--green {
    color: #36b31d;
  }
}
.box-highlight--green:before {
  background-color: #36b31d;
}
@media (min-width: 768px) {
  .box-highlight--green:before {
    color: #36b31d;
  }
}
.box-highlight--purple {
  border-left-color: #b900d1;
}
@media (min-width: 768px) {
  .box-highlight--purple {
    color: #b900d1;
  }
}
.box-highlight--purple:before {
  background-color: #b900d1;
}
@media (min-width: 768px) {
  .box-highlight--purple:before {
    color: #b900d1;
  }
}
.box-highlight--brown {
  border-left-color: #d17d00;
}
@media (min-width: 768px) {
  .box-highlight--brown {
    color: #d17d00;
  }
}
.box-highlight--brown:before {
  background-color: #d17d00;
}
@media (min-width: 768px) {
  .box-highlight--brown:before {
    color: #d17d00;
  }
}
.box-highlight--gray {
  border-left-color: #c1c1c1;
}
@media (min-width: 768px) {
  .box-highlight--gray {
    color: #c1c1c1;
  }
}
.box-highlight--gray:before {
  background-color: #c1c1c1;
}
@media (min-width: 768px) {
  .box-highlight--gray:before {
    color: #c1c1c1;
  }
}
.video-responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.square-decoration:before {
  content: "";
  position: absolute;
  width: 70%;
  height: 50%;
  min-height: 600px;
  top: -30%;
  left: 0;
  margin: auto;
  max-width: 1000px;
  background-color: rgba(18, 188, 255, 0.1);
  z-index: -1;
  display: block;
}
@media (min-width: 576px) {
  .square-decoration:before {
    top: -20%;
  }
}
@media (min-width: 768px) {
  .square-decoration:before {
    top: -25%;
    right: 0;
    left: -350px;
  }
}
.image-float {
  position: absolute;
  left: 0;
  right: 0;
  display: inline-block;
  text-align: center;
}
.image-float--top {
  top: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.image-float--circle-outline:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 130px;
  width: 130px;
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  border: 3px solid #ffb600;
}
.border-divider-top {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #12bcff;
}
.border-divider-top--width-5 {
  border-top-width: 5px;
}
.triangle-top:after,
.triangle-bottom:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #12bcff transparent;
  margin: auto;
}
.triangle-bottom:after {
  bottom: auto;
  top: 100%;
  border-width: 20px 20px 0 20px;
  border-color: #12bcff transparent transparent transparent;
}
.triangle-bottom--primary-accent:after {
  border-color: #172958 transparent transparent transparent;
}
.bg-gradient-primary {
  background: #12bcff;
  background: -moz-linear-gradient(left, #12bcff 0%, #172958 100%);
  background: -webkit-linear-gradient(left, #12bcff 0%, #172958 100%);
  background: linear-gradient(to right, #12bcff 0%, #172958 100%);
}
.bullet-list__item {
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
}
.bullet-list__item:last-child {
  margin-bottom: 0px;
}
.bullet-list__item:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "";
  font-size: 16px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 0;
  color: #12bcff;
}
.bullet-list__item-light:before {
  color: #fff;
}
.bullet-list--disc .bullet-list__item:before {
  content: "";
  font-size: 8px;
}
.roman-list {
  counter-reset: item;
  list-style-type: upper-roman;
}
.roman-list__item {
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
}
.roman-list__item:last-child {
  margin-bottom: 0px;
}
.roman-list__item:before {
  font-weight: 600;
  content: counters(item, ".", upper-roman) " ";
  position: absolute;
  counter-increment: item;
  list-style: inherit;
  left: 0;
  font-size: 16px;
  top: 0;
  width: 30px;
  color: #12bcff;
  font-family: Times, Times New Roman, serif;
}
.roman-list--box-highlight {
  position: relative;
  padding: 20px;
  margin-left: -20px;
}
.roman-list--box-highlight:before {
  content: "";
  height: 100%;
  width: calc(100% - 60px);
  right: 0;
  top: 0;
  position: absolute;
  display: block;
  z-index: -1;
  background-color: rgba(18, 188, 255, 0.1);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.roman-list--box-highlight .roman-list__item {
  padding-left: 60px;
}
.roman-list--box-highlight .roman-list__item:before {
  text-align: right;
}
.column-separator {
  width: 100%;
  height: 2px;
}
@media (min-width: 576px) {
  .column-separator--sm-vertical {
    width: 2px;
    height: 100%;
  }
}
.profile-pic {
  height: 120px;
  width: 120px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  position: relative;
}
.profile-pic--outline:before {
  content: "";
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  border: 2px solid #12bcff;
  position: absolute;
  left: -10px;
  top: -10px;
}
.box-shadow {
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
.map {
  width: 100%;
  height: 500px;
}
.map--full {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}
.bg-image {
  background-position: 200% 0;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  position: relative;
}
.bg-image:before {
  content: "";
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  background-image: inherit;
  background-position: center;
  height: 100%;
  width: 100%;
}
.bg-image--opacity-20:before {
  filter: alpha(opacity=20);
  -moz-opacity: 0.2;
  opacity: 0.2;
}
.bg-image--opacity-15:before {
  filter: alpha(opacity=15);
  -moz-opacity: 0.15;
  opacity: 0.15;
}
.bg-image--opacity-10:before {
  filter: alpha(opacity=10);
  -moz-opacity: 0.1;
  opacity: 0.1;
}
.bg-partial-bottom:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 200px;
  bottom: 0;
  left: 0;
}
.bg-partial-bottom--light:after {
  background-color: #fff;
  background: #fff;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    white 100%
  );
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
}
.triangle-decoration:after {
  content: "";
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #12bcff transparent;
  left: 0;
  right: 0;
  margin: auto;
}
.triangle-decoration--hero:after {
  border-width: 0 20px 20px 20px;
}
.triangle-decoration--light:after {
  border-color: transparent transparent #fff transparent;
}
.triangle-decoration--dark-2:after {
  border-color: transparent transparent #6f6f6f transparent;
}
.triangle-decoration--bottom:after {
  bottom: 0;
}
.triangle-decoration--theme-1:after {
  border-color: transparent transparent #172958 transparent;
}
@media (min-width: 992px) {
  .triangle-decoration--theme-1:after {
    top: 0;
    bottom: auto;
    right: auto;
    left: -10px;
    margin: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    border-color: transparent #172958 transparent transparent;
  }
}
.anchor {
  height: 10px;
  z-index: -99;
  position: absolute;
  left: 0;
  bottom: calc(100% + 78px);
}
@media (min-width: 768px) {
  .anchor {
    bottom: calc(100% + 118px);
  }
}
@media (min-width: 768px) {
  .anchor--lg {
    bottom: calc(100% + 138px);
  }
}
.alert-primary {
  color: #2f69be;
  background-color: #ccf2ff;
  border-color: #b7edff;
}
.alert-primary hr {
  border-top-color: #9ee7ff;
}
.alert-primary .alert-link {
  color: #172958;
}
.badge-primary {
  color: #fff;
  background-color: #12bcff;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  background-color: #172958;
}
.bg-primary {
  background-color: #12bcff !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #172958 !important;
}
.border-primary {
  border-color: #12bcff !important;
}
.btn-primary {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #172958;
  border-color: #172958;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #172958;
  border-color: #1e4a89;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.btn-outline-primary {
  color: #12bcff;
  background-color: transparent;
  border-color: #12bcff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #12bcff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #12bcff;
  border-color: #12bcff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 194, 255, 0.5);
}
.list-group-item-primary {
  color: #2f69be;
  background-color: #b7edff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #2f69be;
  background-color: #9ee7ff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2f69be;
  border-color: #2f69be;
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b7edff;
}
.table-hover .table-primary:hover {
  background-color: #9ee7ff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9ee7ff;
}
.text-primary {
  color: #12bcff !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #172958 !important;
}
.split-name {
  background-color: #fff;
}
.color-light {
  color: #fff;
}
.color-light-hover:hover {
  color: #fff;
}
.bg-light {
  background-color: #fff;
}
.split-name {
  background-color: #fff;
}
.color-light-theme {
  color: #fff;
}
.color-light-theme-hover:hover {
  color: #fff;
}
.bg-light-theme {
  background-color: #fff;
}
.split-name {
  background-color: #36b31d;
}
.color-green {
  color: #36b31d;
}
.color-green-hover:hover {
  color: #36b31d;
}
.bg-green {
  background-color: #36b31d;
}
.split-name {
  background-color: #b900d1;
}
.color-purple {
  color: #b900d1;
}
.color-purple-hover:hover {
  color: #b900d1;
}
.bg-purple {
  background-color: #b900d1;
}
.split-name {
  background-color: #2f69be;
}
.color-primary-deep {
  color: #2f69be;
}
.color-primary-deep-hover:hover {
  color: #2f69be;
}
.bg-primary-deep {
  background-color: #2f69be;
}
.split-name {
  background-color: #172958;
}
.color-primary-accent {
  color: #172958;
}
.color-primary-accent-hover:hover {
  color: #172958;
}
.bg-primary-accent {
  background-color: #172958;
}
.split-name {
  background-color: #d17d00;
}
.color-brown {
  color: #d17d00;
}
.color-brown-hover:hover {
  color: #d17d00;
}
.bg-brown {
  background-color: #d17d00;
}
.split-name {
  background-color: #000;
}
.color-black {
  color: #000;
}
.color-black-hover:hover {
  color: #000;
}
.bg-black {
  background-color: #000;
}
.split-name {
  background-color: #606060;
}
.color-darkness {
  color: #606060;
}
.color-darkness-hover:hover {
  color: #606060;
}
.bg-darkness {
  background-color: #606060;
}
.split-name {
  background-color: #e2e2e2;
}
.color-light-gray {
  color: #e2e2e2;
}
.color-light-gray-hover:hover {
  color: #e2e2e2;
}
.bg-light-gray {
  background-color: #e2e2e2;
}
.split-name {
  background-color: #ddf8ff;
}
.color-light-blue {
  color: #ddf8ff;
}
.color-light-blue-hover:hover {
  color: #ddf8ff;
}
.bg-light-blue {
  background-color: #ddf8ff;
}
.split-name {
  background-color: #f5e5e5;
}
.color-light-red {
  color: #f5e5e5;
}
.color-light-red-hover:hover {
  color: #f5e5e5;
}
.bg-light-red {
  background-color: #f5e5e5;
}
.split-name {
  background-color: #eaeaea;
}
.color-highlight {
  color: #eaeaea;
}
.color-highlight-hover:hover {
  color: #eaeaea;
}
.bg-highlight {
  background-color: #eaeaea;
}
.split-name {
  background-color: #6f6f6f;
}
.color-dark {
  color: #6f6f6f;
}
.color-dark-hover:hover {
  color: #6f6f6f;
}
.bg-dark {
  background-color: #6f6f6f;
}
.split-name {
  background-color: #232323;
}
.color-dark-2 {
  color: #232323;
}
.color-dark-2-hover:hover {
  color: #232323;
}
.bg-dark-2 {
  background-color: #232323;
}
.split-name {
  background-color: #c1c1c1;
}
.color-gray {
  color: #c1c1c1;
}
.color-gray-hover:hover {
  color: #c1c1c1;
}
.bg-gray {
  background-color: #c1c1c1;
}
.split-name {
  background-color: #12bcff;
}
.color-primary {
  color: #12bcff;
}
.color-primary-hover:hover {
  color: #12bcff;
}
.bg-primary {
  background-color: #12bcff;
}
.split-name {
  background-color: #ffb600;
}
.color-secondary {
  color: #ffb600;
}
.color-secondary-hover:hover {
  color: #ffb600;
}
.bg-secondary {
  background-color: #ffb600;
}
.split-name {
  background-color: #ffb600;
}
.color-secondary-theme {
  color: #ffb600;
}
.color-secondary-theme-hover:hover {
  color: #ffb600;
}
.bg-secondary-theme {
  background-color: #ffb600;
}
.split-name {
  background-color: #fe6154;
}
.color-secondary-accent {
  color: #fe6154;
}
.color-secondary-accent-hover:hover {
  color: #fe6154;
}
.bg-secondary-accent {
  background-color: #fe6154;
}
.split-name {
  background-color: #ec7700;
}
.color-orange {
  color: #ec7700;
}
.color-orange-hover:hover {
  color: #ec7700;
}
.bg-orange {
  background-color: #ec7700;
}
.bg-color-primary-alpha-60 {
  background-color: rgba(18, 188, 255, 0.6);
}
.bg-color-primary-alpha-40 {
  background-color: rgba(18, 188, 255, 0.4);
}
.fw-light {
  font-weight: 300;
}
.fw-bold {
  font-weight: 700;
}
.fw-semibold {
  font-weight: 600;
}
.fw-black {
  font-weight: 900;
}
.h-font-weight-light {
  font-weight: 300;
}
.h-font-weight-regular {
  font-weight: 400;
}
.h-font-weight-bold {
  font-weight: 600;
}
.h-font-weight-heavy {
  font-weight: 800;
}
.h-font-weight-black {
  font-weight: 900;
}
.h-font-icon-size-xs {
  font-size: 12px;
}
.h-font-icon-size-sm {
  font-size: 16px;
}
.h-font-icon-size-hero {
  font-size: 80px;
}
.h-font-text-size-xxs {
  font-size: 14px;
}
.h-font-text-size-xs {
  font-size: 14px;
}
.h-font-text-size-sm {
  font-size: 16px;
}
.h-font-text-size-md {
  font-size: 18px;
}
.h-font-text-size-lg {
  font-size: 20px;
}
.h-font-text-size-xl {
  font-size: 20px;
}
@media (min-width: 768px) {
  .h-font-text-size-xl {
    font-size: 25px;
  }
}
.h-text-uppercase {
  text-transform: uppercase;
}
.h-text-underline {
  text-decoration: underline;
}
.h-line-height-minimal {
  line-height: 1.3;
}
.h-line-height-1 {
  line-height: 1;
}
@media (min-width: 768px) {
  .h-mx-width-md-300 {
    width: 300px;
  }
}
.h-min-width-300 {
  min-width: 300px;
}
.w-100px {
  width: 100px;
}
.w-115px {
  width: 115px;
}
@media (min-width: 768px) {
  .w-md-230px {
    width: 230px;
  }
}
@media (min-width: 768px) {
  .w-md-200px {
    width: 200px;
  }
}
@media (min-width: 768px) {
  .w-md-215px {
    width: 215px;
  }
}
.h-min-height-300 {
  min-height: 300px;
}
.h-min-height-300-theme-1 {
  min-height: 300px;
}
@media (min-width: 768px) {
  .h-min-height-300-theme-1 {
    min-height: 200px;
  }
}
.h-min-height-400 {
  min-height: 400px;
}
input {
  font-family: "Roboto", sans-serif;
}
input::-webkit-input-placeholder {
  font-weight: 300;
}
input::-moz-placeholder {
  font-weight: 300;
}
input:-ms-input-placeholder {
  font-weight: 300;
}
input:-moz-placeholder {
  font-weight: 300;
}
.transition-03 {
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.h-overflow-auto {
  overflow: auto;
}
.h-overflow-hidden {
  overflow: hidden;
}
.h-border-width-4 {
  border-width: 4px !important;
}
.h-position-relative {
  position: relative;
}
.h-position-inital {
  position: initial;
}
.h-corner-10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.h-corner-15 {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.h-corner-circle {
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
}
.bg-primary-accent-07 {
  background-color: rgba(23, 41, 88, 0.7);
}
.bg-color-primary-alpha-60 {
  background-color: rgba(18, 188, 255, 0.6);
}
.bg-color-primary-alpha-40 {
  background-color: rgba(18, 188, 255, 0.4);
}
.bg-img-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bg-img-cover--position-center {
  background-position: center center;
}
.z-index-5 {
  z-index: 5;
}
.home-intro {
  background-color: #d2e8f0;
  color: #000;
  min-height: 700px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  font-size: 20px;
  background-repeat: no-repeat;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  background-position: top center;
  padding: 0;
}
@media (min-width: 768px) {
  .home-intro {
    text-align: justify;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 20%;
  }
}
@media (min-width: 992px) {
  .home-intro {
  }
}
.home-intro .headline-title {
  font-size: 35px;
  margin-bottom: 0px;
}
.home-intro .headline-title span {
  font-size: 60px;
  display: block;
  margin-bottom: 10px;
}
.home-intro .headline-subtitle {
  font-size: 31px;
}
.home-intro .btn-hero {
  margin-top: 30px;
  font-size: 22px;
  text-transform: capitalize;
}
.home-intro .item-slider {
  width: 100%;
  padding: 40px;
  margin-top: 230px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .home-intro .item-slider {
    padding: 80px 40px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .home-intro .item-slider {
    padding: 100px 40px;
  }
}
.testimonial {
  position: relative;
}
.testimonial .headline-body {
  max-width: 560px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .testimonial .headline-body {
    margin-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .testimonial .headline-body.icon-headline-container {
    padding-left: 90px;
  }
}
.testimonial .headline-body.icon-headline-container .icon-headline {
  position: relative;
  background-color: #12bcff;
  color: #fff;
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .testimonial .headline-body.icon-headline-container .icon-headline {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 40px;
    height: 40px;
  }
}
.testimonial .testimonial-prev,
.testimonial .testimonial-next {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 2px solid rgba(18, 188, 255, 0.3);
}
.testimonial .testimonial-prev .fas,
.testimonial .testimonial-next .fas {
  position: absolute;
  width: 20px;
  height: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  font-size: 16px;
  color: #000;
  line-height: 1;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.testimonial .testimonial-prev .fas:before,
.testimonial .testimonial-next .fas:before {
  color: inherit;
}
.testimonial .testimonial-prev:hover,
.testimonial .testimonial-next:hover {
  background-color: #12bcff;
}
.testimonial .testimonial-prev:hover .fas,
.testimonial .testimonial-next:hover .fas {
  color: #fff;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
@media (min-width: 1365px) {
  .testimonial .testimonial-next {
    right: -80px;
  }
}
.testimonial .testimonial-next:hover .fas {
  left: 10px;
}
@media (min-width: 1365px) {
  .testimonial .testimonial-prev {
    left: -80px;
  }
}
.testimonial .testimonial-prev:hover .fas {
  right: 10px;
}
@media (min-width: 768px) {
  .testimonial .image-container {
    padding-left: 90px;
    padding-right: 20px;
  }
}
@media (min-width: 1365px) {
  .testimonial .image-container {
    padding-left: 15px;
    padding-right: 40px;
  }
}
@media (min-width: 768px) {
  .testimonial .description-container {
    padding-right: 90px;
    padding-left: 20px;
  }
}
@media (min-width: 1365px) {
  .testimonial .description-container {
    padding-right: 15px;
    padding-left: 40px;
  }
}
.testimonial .testimonial-title {
  font-size: 35px;
  font-weight: 800;
  color: #12bcff;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .testimonial .testimonial-title {
    font-size: 50px;
    max-width: 300px;
  }
}
.testimonial .author {
  margin-top: 20px;
  padding-left: 30px;
  position: relative;
}
.testimonial .author:before {
  content: "";
  width: 20px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 9px;
  bottom: 0;
  left: 0;
  display: block;
}
.testimonial .author h3 {
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
}
.testimonial .author p {
  color: #c1c1c1;
}
.program {
  position: relative;
}
@media (min-width: 768px) {
  .program {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.program .headline-body {
  max-width: 560px;
  margin-bottom: 60px;
  color: #12bcff;
}
.program .headline-body:before {
  content: "";
  display: block;
  height: 3px;
  width: 100px;
  background-color: #12bcff;
  position: absolute;
  left: 0;
  top: calc(100% + 20px);
}
@media (min-width: 768px) {
  .program .headline-body:before {
    left: 90px;
  }
}
.program:before {
  content: "";
  position: absolute;
  width: 70%;
  height: 50%;
  min-height: 600px;
  top: -30%;
  left: 0;
  margin: auto;
  max-width: 1000px;
  background-color: rgba(18, 188, 255, 0.1);
  z-index: -1;
  display: block;
}
@media (min-width: 576px) {
  .program:before {
    top: -20%;
  }
}
@media (min-width: 768px) {
  .program:before {
    top: -25%;
    right: 0;
    left: -350px;
  }
}
.program .headline-body {
  max-width: 560px;
  margin-bottom: 60px;
  color: #12bcff;
  position: relative;
}
.program .headline-body:before {
  content: "";
  display: block;
  height: 3px;
  width: 100px;
  background-color: #12bcff;
  position: absolute;
  left: 0;
  top: calc(100% + 20px);
}
@media (min-width: 768px) {
  .program .headline-body:before {
    left: 90px;
  }
}
@media (min-width: 768px) {
  .program .headline-body.icon-headline-container {
    padding-left: 90px;
  }
}
.program .headline-body.icon-headline-container .icon-headline {
  position: relative;
  background-color: #12bcff;
  color: #fff;
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .program .headline-body.icon-headline-container .icon-headline {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 40px;
    height: 40px;
  }
}
.program .collapse-container .card-header {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  display: block;
  padding: 20px 30px 15px 0;
  background-color: transparent;
  border-bottom: 1px solid #12bcff;
  position: relative;
}
.program .collapse-container .card-header .icon-arrow {
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: block;
  width: 20px;
  height: 20px;
  top: 25px;
  right: 0;
  position: absolute;
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  border: 1px solid #12bcff;
  background-color: #12bcff;
}
.program .collapse-container .card-header .icon-arrow .fas,
.program .collapse-container .card-header .icon-arrow .fa {
  color: #fff;
  display: block;
  width: 12px;
  height: 12px;
  line-height: 14px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 12px;
  text-align: center;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}
.program .collapse-container .card-header.collapsed .icon-arrow {
  background-color: transparent;
}
.program .collapse-container .card-header.collapsed .icon-arrow .fas,
.program .collapse-container .card-header.collapsed .icon-arrow .fa {
  color: #12bcff;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}
.program .collapse-container .card-body {
  background-color: #fff;
}
.lifestyle-factors {
  color: #000;
}
.lifestyle-factors .wrap-section {
  width: 100%;
  padding: 40px;
}
@media (min-width: 768px) {
  .lifestyle-factors .wrap-section {
    padding: 80px 40px 40px;
  }
}
@media (min-width: 992px) {
  .lifestyle-factors .wrap-section {
    padding: 100px 40px 40px;
  }
}
.lifestyle-factors .headline-body {
  color: #12bcff;
}
.lifestyle-factors .factors {
  font-weight: bold;
}
.lifestyle-factors .factors img {
  max-width: 50px;
  display: block;
  margin: 0 auto 20px;
}
.physician-directed-personalized {
  background-color: #232323;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
}
@media (min-width: 768px) {
  .physician-directed-personalized {
  }
}
@media (min-width: 992px) {
  .physician-directed-personalized {
  }
}
.physician-directed-personalized .headline-title,
.physician-directed-personalized .headline-subtitle {
  color: inherit;
}
.physician-directed-personalized .top-box-light {
  text-align: center;
  background-color: rgba(18, 188, 255, 0.6);
}
.physician-directed-personalized .top-box-light .headline-subtitle,
.physician-directed-personalized .top-box-light .headline-title {
  margin-bottom: 0px;
}
.physician-directed-personalized .top-box-light .headline-title {
  margin-top: 5px;
  font-size: 25px;
}
.physician-directed-personalized .wrap-section {
  width: 100%;
  padding: 100px 40px 100px;
}
@media (min-width: 768px) {
  .physician-directed-personalized .wrap-section {
    padding: 150px 40px 150px;
  }
}
@media (min-width: 992px) {
  .physician-directed-personalized .wrap-section {
    padding: 200px 40px 200px;
  }
}
.physician-directed-personalized .wrap-section .headline-title {
  font-size: 25px;
}
.physician-directed-personalized .wrap-section .headline-subtitle {
  font-size: 25px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.physician-directed-personalized .wrap-section .wrap-body {
  line-height: 1.4;
}
.physician-directed-personalized .wrap-section .btn-headline {
  margin-top: 40px;
}
.subscription {
  background-color: #000;
  position: relative;
}
.subscription:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-position: center;
  background-image: url("../images/trellus_background.png");
}
@media (min-width: 768px) {
  .subscription:before {
    background-image: url("../images/trellus_background.png");
  }
}
@media (min-width: 992px) {
  .subscription:before {
    background-image: url("../images/trellus_background.png");
  }
}
.subscription.form-container {
  background-color: #232323;
}
.subscription.form-container:before {
  background-attachment: fixed;
  filter: alpha(opacity=20);
  -moz-opacity: 0.2;
  opacity: 0.2;
}
.subscription .boxed {
  position: relative;
  z-index: 1;
}
.subscription .headline-body {
  position: relative;
  color: #12bcff;
}
.subscription .headline-body:before {
  content: "";
  display: block;
  height: 3px;
  width: 100px;
  background-color: #00c2ff;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(100% + 15px);
}
.subscription .headline-subtitle {
  color: #fff;
  max-width: 700px;
  margin: 40px auto 0;
}
.subscription .card {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin: 20px auto;
  color: #fff;
  height: 100%;
  max-width: 400px;
  overflow: hidden;
  background-color: #232323;
}
.subscription .card.plan-b:before,
.subscription .card.plan-a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-position: center;
}
.subscription .card.plan-b:hover:before,
.subscription .card.plan-a:hover:before {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}
.subscription .card.plan-b:before {
}
.subscription .card.plan-a:before {
}
.subscription .card .card-body {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
@media (min-width: 576px) {
  .subscription .card .card-body {
    padding: 50px;
  }
}
.subscription .card .headline-title {
  font-size: 35px;
  margin: 20px 0;
  color: #fff;
  line-height: 1;
}
.subscription .card .headline-subtitle {
  margin: 0;
  color: #fff;
}
.subscription .card .note {
  margin: 40px 0 20px;
  font-size: 14px;
  line-height: 1.3;
  font-style: italic;
  font-weight: 300;
}
.subscription .card .middle-price {
  margin: auto 0;
}
.subscription .card .subscription-container-btn {
  margin-top: auto;
}
.subscription .card .subscription-container-btn .btn-suscription {
  display: block;
  font-size: 20px;
}
.intro-section:before {
}
@media (min-width: 768px) {
  .intro-section:before {
  }
}
@media (min-width: 992px) {
  .intro-section:before {
  }
}
.reserve-my-seat {
  border-top: 5px solid #12bcff;
}
.reserve-my-seat:before {
}
@media (min-width: 768px) {
  .reserve-my-seat:before {
  }
}
@media (min-width: 992px) {
  .reserve-my-seat:before {
    background-position: bottom center;
  }
}
.meet-your-host:before {
  top: -5%;
  min-height: 800px;
}
@media (min-width: 768px) {
  .meet-your-host:before {
    min-height: 600px;
  }
}
.about-cover:before {
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  opacity: 0.7;
}
@media (min-width: 768px) {
  .about-cover:before {
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .about-cover:before {
    background-position: bottom center;
  }
}
.section-benefits:before {
}
@media (min-width: 768px) {
  .section-benefits:before {
  }
}
@media (min-width: 992px) {
  .section-benefits:before {
    background-position: bottom center;
  }
}
.book-cover-shadow {
  box-shadow: 2rem 4rem 6rem rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2rem 4rem 6rem rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2rem 4rem 6rem rgba(0, 0, 0, 0.15);
}
.form-subscription-box {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.3);
}
.form-subscription-box .form-subscription-content {
  background-color: #fff;
  color: #000;
  padding: 40px;
}
.form-subscription-box .form-subscription-content form,
.form-subscription-box .form-subscription-content > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form-subscription-box .form-subscription-content .headline-title {
  color: #fff;
  font-size: 25px;
}
.form-subscription-box .form-subscription-content .form-group label {
  font-size: 14px;
}
.form-subscription-box .form-subscription-content .box-information {
  font-size: 14px;
  max-height: 150px;
  overflow: auto;
  color: #6f6f6f;
  padding: 20px;
  background-color: rgba(18, 188, 255, 0.1);
}
.form-subscription-box .form-subscription-content .box-information h2 {
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}
.form-subscription-box .form-subscription-content .box-information h3 {
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
}
.form-subscription-box .form-subscription-content .thank-you-section {
  max-width: 500px;
  margin: 20px auto;
  text-align: center;
}
.form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-body,
.form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-subtitle {
  color: #fff;
  margin: 0 auto 10px;
  font-weight: 300;
}
.form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-body:before,
.form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-subtitle:before {
  content: none;
}
.form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-body {
  font-size: 50px;
  line-height: 1;
}
.form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-subtitle {
  font-size: 25px;
}
.form-subscription-box
  .form-subscription-content
  .thank-you-section
  .image-payment {
  width: 100%;
  max-width: 250px;
  margin: 0 auto 20px;
}
.form-subscription-box .form-subscription-content .btn-payment {
  font-size: 22px;
}
.form-subscription-box .form-subscription-cover {
  padding: 40px;
  background-color: #232323;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  position: relative;
  color: #fff;
}
@media (min-width: 768px) {
  .form-subscription-box .form-subscription-cover {
    max-width: 350px;
  }
}
@media (min-width: 992px) {
  .form-subscription-box .form-subscription-cover {
    max-width: 450px;
  }
}
.form-subscription-box .form-subscription-cover .plan-image-container {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4;
}
.form-subscription-box .form-subscription-cover .plan-image-container img {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.form-subscription-box
  .form-subscription-cover
  .form-subscription-cover-content {
  position: relative;
  z-index: 2;
}
.form-subscription-box .form-subscription-cover .headline-title {
  font-size: 35px;
  line-height: 1;
  color: #fff;
  margin: 10px 0;
}
.form-subscription-box .form-subscription-cover .headline-subtitle {
  color: #fff;
  margin: 0 auto 30px;
}
.form-subscription-box .form-subscription-cover .note {
  margin: 40px 0 20px;
  font-size: 14px;
  line-height: 1.3;
  font-style: italic;
  font-weight: 300;
}
.form-subscription-box .form-subscription-cover .headline-steps {
  margin-bottom: 40px;
}
.form-subscription-box .form-subscription-cover .headline-steps .headline-body {
  color: inherit;
}
.form-subscription-box .form-subscription-cover .nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  color: #fff;
  text-align: center;
}
.form-subscription-box .form-subscription-cover .nav .nav-link {
  color: #fff;
  border: 1px solid #fff;
  height: 40px;
  width: 40px;
  display: block;
  margin: 0px 7px;
  padding: 0;
  position: relative;
  -webkit-border-radius: 200%;
  -moz-border-radius: 200%;
  border-radius: 200%;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.form-subscription-box .form-subscription-cover .nav .nav-link.active {
  background: #fff;
  color: #12bcff;
}
.form-subscription-box .form-subscription-cover .nav .nav-link:before {
  content: "";
  width: 15px;
  height: 1px;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  background-color: #fff;
  margin: auto;
}
.form-subscription-box .form-subscription-cover .nav .nav-link .headline-body {
  font-weight: 400;
  color: inherit;
  height: 40px;
  width: 40px;
  position: relative;
  display: block;
}
.form-subscription-box
  .form-subscription-cover
  .nav
  .nav-link
  .headline-body:before {
  content: none;
}
.form-subscription-box
  .form-subscription-cover
  .nav
  .nav-link
  .headline-body
  .tab-number {
  display: block;
  height: 40px;
  text-align: center;
  line-height: 38px;
  font-size: 20px;
}
.form-subscription-box
  .form-subscription-cover
  .nav
  .nav-item.visited
  .nav-link {
  background: #fff;
  color: #12bcff;
}
.form-subscription-box
  .form-subscription-cover
  .nav
  .nav-item:last-child
  .nav-link:before {
  content: none;
}
.form-subscription-box .back_page,
.form-subscription-box .next_page {
  margin-bottom: 5px;
  margin-top: 5px;
}
.form-subscription-box .back_page:focus,
.form-subscription-box .next_page:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid #12bcff;
}
body.landing-body {
  padding-top: 83px;
}
body.landing-body .navbar-container.landing-header--light {
  min-height: 83px;
}
body.landing-body .navbar-container.landing-header--light .navbar-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}
body.landing-body .landing-footer--light {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
}
body.landing-body .text_xs {
  font-size: 12px;
}
body.landing-body .text_sm {
  font-size: 15px;
}
body.landing-body .text_lg {
  font-size: 25px;
}
body.landing-body .btn-link-primary {
  color: #9e2358;
}
body.landing-body .btn-link-primary:hover {
  color: #63b49b;
}
body.landing-body .btn-link-dark {
  color: #6f6f6f;
}
body.landing-body .btn-link-dark:hover {
  color: #9e2358;
}
body.landing-body .btn-link-darkness {
  color: #000;
}
body.landing-body .btn-link-darkness:hover {
  color: #9e2358;
}
body.landing-body .btn-primary {
  background-color: #9e2358;
  border-color: #9e2358;
}
body.landing-body .btn-primary:hover {
  background-color: #63b49b;
  border-color: #63b49b;
}
body.landing-body .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 180, 155, 0.5);
  border-color: #63b49b;
  background-color: #63b49b;
}
body.landing-body .btn-outline-primary {
  color: #9e2358;
  border-color: #9e2358;
  background-color: transparent;
}
body.landing-body .btn-outline-primary:focus,
body.landing-body .btn-outline-primary:hover {
  background-color: #63b49b;
  border-color: #63b49b;
  color: #fff;
}
body.landing-body .btn-outline-primary:not(:disabled):not(.disabled):active,
body.landing-body .btn-outline-primary:not(:disabled):not(.disabled).active,
body.landing-body .show > .btn-outline-primary.dropdown-toggle {
  background-color: #63b49b;
  box-shadow: 0 0 0 0.2rem rgba(99, 180, 155, 0.5);
  border-color: transparent;
}
body.landing-body .btn-primary:not(:disabled):not(.disabled):active,
body.landing-body .btn-primary:not(:disabled):not(.disabled).active,
body.landing-body .show > .btn-primary.dropdown-toggle {
  background-color: #63b49b;
  border-color: #63b49b;
}
body.landing-body .btn-primary:not(:disabled):not(.disabled):active:focus,
body.landing-body .btn-primary:not(:disabled):not(.disabled).active:focus,
body.landing-body .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 180, 155, 0.5);
}
body.landing-body .subscription .headline-body {
  color: #fff;
}
body.landing-body .subscription .headline-body:before {
  background-color: #51685b;
}
body.landing-body .subscription .headline-subtitle {
  color: #fff;
}
body.landing-body
  .form-subscription-box
  .form-subscription-content
  .headline-title {
  color: #fff;
}
body.landing-body
  .form-subscription-box
  .form-subscription-cover
  .nav
  .nav-item.visited
  .nav-link,
body.landing-body
  .form-subscription-box
  .form-subscription-cover
  .nav
  .nav-link.active {
  color: #9e2358;
}
body.landing-body .form-subscription-cover {
  position: relative;
}
body.landing-body .form-subscription-cover:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #232323;
  background: -moz-linear-gradient(
    top,
    rgba(35, 35, 35, 0) 0%,
    rgba(35, 35, 35, 0.7) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(35, 35, 35, 0) 0%,
    rgba(35, 35, 35, 0.7) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(35, 35, 35, 0) 0%,
    rgba(35, 35, 35, 0.7) 100%
  );
  z-index: 1;
}
body.landing-body
  .form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-body,
body.landing-body
  .form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-subtitle {
  color: #000;
}
body.landing-body .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 35, 88, 0.2);
  border-color: rgba(158, 35, 88, 0.5);
}
body.landing-body
  .form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-body,
body.landing-body
  .form-subscription-box
  .form-subscription-content
  .thank-you-section
  .headline-subtitle {
  color: #000;
}
body.landing-body
  .form-subscription-box
  .form-subscription-content
  .thank-you-section
  p {
  color: #6f6f6f;
}
body.landing-body .landing_copyright {
  color: #000;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
}
body.landing-body .subscription .headline-subtitle {
  margin: 30px auto 10px;
  max-width: none;
  font-size: 18px;
}
body.landing-body
  .form-subscription-box
  .form-subscription-cover
  .headline-steps {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  body.landing-body .row-divider-md > *:not(:last-child):before {
    content: "";
    top: 5px;
    right: 0;
    height: 10px;
    width: 1px;
    position: absolute;
    background-color: #606060;
  }
}
@media (min-width: 768px) {
  body.landing-body .subscription {
    padding: 40px;
  }
}
@media (max-width: 991px) {
  body.landing-body .navbar-wrapper {
    padding: 20px 25px;
    position: relative;
  }
  body.landing-body .navbar-item {
    padding: 0;
    background-color: #fff;
    width: 100%;
    top: 100%;
    left: 0;
    position: absolute;
    max-height: 0;
    overflow: hidden;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  body.landing-body .navbar-item__links {
    padding-bottom: 20px;
  }
  body.landing-body .navbar-item__links .btn {
    width: 100%;
    text-align: left;
    padding: 15px 25px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  body.landing-body .navbar-item__links .btn:focus {
    border: 1px solid transparent;
  }
  body.landing-body .navbar-item__links .btn.active,
  body.landing-body .navbar-item__links .btn:hover {
    background-color: #6f6f6f;
    color: #fff;
  }
  body.landing-body .navbar-item__links .btn-primary {
    background-color: transparent !important;
    color: #6f6f6f !important;
    border-color: transparent !important;
  }
  body.landing-body .navbar-item__links .btn-primary:hover {
    border-color: transparent !important;
    color: #9e2358 !important;
    background-color: transparent !important;
  }
  body.landing-body .menu-toggle {
    background-color: #ffc000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 0 2px;
    position: relative;
  }
  body.landing-body .menu-toggle:before {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 20px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 18px;
    width: 18px;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    opacity: 0;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  body.landing-body .menu-toggle .fas {
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  body.landing-body:not(.toggled) .navbar-item {
    max-height: 30rem;
  }
  body.landing-body:not(.toggled) .menu-toggle:before {
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
  }
  body.landing-body:not(.toggled) .fas {
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    opacity: 0;
  }
}
.list__item {
  display: none;
}
.bullet-list__item {
  position: relative;
  padding-left: 25px;
  font-size: 13px;
  margin-bottom: 0;
}
.bullet-list__item:before {
  color: #fff;
  font-size: 12px;
} /*# sourceMappingURL=main.css.map */
